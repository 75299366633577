import React from 'react';
import {useIntl} from "react-intl";
import SkillSet from "./skillSet"
import {LocalizedLink} from "gatsby-theme-i18n";

const Stack = () => {
    const {formatMessage} = useIntl();
    const f = (id) => formatMessage({id});
    return (
        <div className='min-h-screen bg-gray flex py-8 md:py-14 gap-4 flex flex-col justify-around'>
            <div className='flex flex-col md:flex-row justify-between px-6 md:px-14'>
                <p className='font-ExtraBold text-5xl xl:text-6xl text-white'>
                    {f("hiThisIs")}<br/>{"DUMA studio."}
                </p>
                <div className='flex flex-col gap-4 md:w-1/2 mt-6 md:mt-0'>
                    <p className='font-Regular text-lg xl:text-xl leading-relaxed xl:leading-loose text-white'>{f("job")}</p>
                    <LocalizedLink to='/testimonials' className='flex gap-1 justify-start items-center z-10'>
                        <span className='font-Bold text-lg md:text-sm xl:text-lg text-white'>{f("learnMoreAbout")}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#ffffff" width={22} height={22}>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7"/>
                        </svg>
                    </LocalizedLink>
                </div>
            </div>
            <div className='w-full rounded-10px'>
                <div className='flex flex-col md:flex-row justify-between px-6 md:px-14'>
                    <p className='font-ExtraLight tracking-widest opacity-75 text-white'>{f("skillSet")}</p>
                </div>
                <div className="overflow-hidden">
                    <div className='block md:hidden'>
                        <SkillSet value={6500}/>
                    </div>
                    <div className='hidden md:block xl:hidden'>
                        <SkillSet value={6350}/>
                    </div>
                    <div className='hidden md:hidden xl:block'>
                        <SkillSet value={6200}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Stack;
