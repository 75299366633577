import React from 'react';
import {LocalizedLink} from "gatsby-theme-i18n";

const ButtonLink = ({label, link}) => {
    return (
        <LocalizedLink to={link} className='flex gap-1 justify-start items-center z-10'>
            <span className='font-Bold text-lg md:text-sm xl:text-lg'>{label}</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width={22} height={22}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7"/>
            </svg>
        </LocalizedLink>
    );
};

export default ButtonLink;
