import React from 'react';
import Img from "gatsby-image";
import {motion} from "framer-motion";
import {graphql, useStaticQuery} from "gatsby";

const SkillSet = ({value}) => {
    const images = useStaticQuery(graphql`
    query {
      androidDark: file(relativePath: { eq: "stack/android-dark.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      angularDark: file(relativePath: { eq: "stack/angular-dark.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      firebaseDark: file(relativePath: { eq: "stack/firebase-dark.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      flutterDark: file(relativePath: { eq: "stack/flutter-dark.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      framerDark: file(relativePath: { eq: "stack/framer-dark.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gatsbyDark: file(relativePath: { eq: "stack/gatsby-dark.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gitDark: file(relativePath: { eq: "stack/git-dark.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      htmlDark: file(relativePath: { eq: "stack/html-js-css-dark.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nextDark: file(relativePath: { eq: "stack/next-dark.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      npmDark: file(relativePath: { eq: "stack/npm-yarn-dark.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nuxtDark: file(relativePath: { eq: "stack/nuxt-dark.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      reactDark: file(relativePath: { eq: "stack/react-dark.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      restDark: file(relativePath: { eq: "stack/rest-xmpp-dark.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stripeDark: file(relativePath: { eq: "stack/stripe-dark.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tailwindcssDark: file(relativePath: { eq: "stack/tailwindcss-dark.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      swiftui: file(relativePath: { eq: "stack/swiftui.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
    return (
        <motion.div
            className="item-panel-wrapper flex gap-4 md:gap-16 p-6 md:p-14"
            drag="x"
            dragConstraints={{left: -value, right: 0}}
            initial={{opacity: 0.8, x: 1000}}
            animate={{opacity: 1, x: 0, transition: {duration: 1.0}}}
        >
            <div className="cursor-pointer">
                <Img
                    style={{height: 300, width: 440}}
                    fluid={images.flutterDark.childImageSharp.fluid}
                    imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                />
            </div>
            <div className="cursor-pointer">
                <Img
                    style={{height: 300, width: 440}}
                    fluid={images.reactDark.childImageSharp.fluid}
                    imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                />
            </div>
            <div className="cursor-pointer">
                <Img
                    style={{height: 300, width: 440}}
                    fluid={images.nextDark.childImageSharp.fluid}
                    imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                />
            </div>
            <div className="cursor-pointer">
                <Img
                    style={{height: 300, width: 440}}
                    fluid={images.nuxtDark.childImageSharp.fluid}
                    imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                />
            </div>
            <div className="cursor-pointer">
                <Img
                    style={{height: 300, width: 440}}
                    fluid={images.swiftui.childImageSharp.fluid}
                    imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                />
            </div>
            <div className="cursor-pointer">
                <Img
                    style={{height: 300, width: 440}}
                    fluid={images.gatsbyDark.childImageSharp.fluid}
                    imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                />
            </div>
            <div className="cursor-pointer">
                <Img
                    style={{height: 300, width: 440}}
                    fluid={images.angularDark.childImageSharp.fluid}
                    imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                />
            </div>
            <div className="cursor-pointer">
                <Img
                    style={{height: 300, width: 440}}
                    fluid={images.htmlDark.childImageSharp.fluid}
                    imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                />
            </div>
            <div className="cursor-pointer">
                <Img
                    style={{height: 300, width: 440}}
                    fluid={images.tailwindcssDark.childImageSharp.fluid}
                    imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                />
            </div>
            <div className="cursor-pointer">
                <Img
                    style={{height: 300, width: 440}}
                    fluid={images.stripeDark.childImageSharp.fluid}
                    imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                />
            </div>
            <div className="cursor-pointer">
                <Img
                    style={{height: 300, width: 440}}
                    fluid={images.framerDark.childImageSharp.fluid}
                    imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                />
            </div>
            <div className="cursor-pointer">
                <Img
                    style={{height: 300, width: 440}}
                    fluid={images.npmDark.childImageSharp.fluid}
                    imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                />
            </div>
            <div className="cursor-pointer">
                <Img
                    style={{height: 300, width: 440}}
                    fluid={images.gitDark.childImageSharp.fluid}
                    imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                />
            </div>
            <div className="cursor-pointer">
                <Img
                    style={{height: 300, width: 440}}
                    fluid={images.androidDark.childImageSharp.fluid}
                    imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                />
            </div>
            <div className="cursor-pointer">
                <Img
                    style={{height: 300, width: 440}}
                    fluid={images.firebaseDark.childImageSharp.fluid}
                    imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                />
            </div>
            <div className="cursor-pointer">
                <Img
                    style={{height: 300, width: 440}}
                    fluid={images.restDark.childImageSharp.fluid}
                    imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                />
            </div>
        </motion.div>
    );
};

export default SkillSet;
