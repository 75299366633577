import * as React from "react";
import {useIntl} from "react-intl";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Loader from "../components/loader";
import ButtonLink from "../components/buttonLink";
import {motion} from "framer-motion";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import wave from "../images/wave.svg"
import waveDark from "../images/waveDark.svg"
import {useColorMode} from "theme-ui"
import Stack from '../components/stack'
import {useLocalization} from "gatsby-theme-i18n";

const IndexPage = (props) => {
    const {formatMessage} = useIntl();
    const f = (id) => formatMessage({id});

    const [colorMode] = useColorMode()
    const isDark = colorMode === `dark`

    const {locale} = useLocalization()

    const images = useStaticQuery(graphql`
    query {
      diegoUlises: file(relativePath: { eq: "Diego Ulises.png" }) {
        childImageSharp {
          fluid(maxWidth: 420) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      agendaCaveHome: file(relativePath: { eq: "portfolio/agendaCaveHome.png" }) {
        childImageSharp {
          fluid(maxWidth: 420) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      despensaApp: file(relativePath: { eq: "portfolio/despensaAppHome.png" }) {
        childImageSharp {
          fluid(maxWidth: 420) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    const [hasMounted, setHasMounted] = React.useState(false)
    React.useEffect(() => {
        setHasMounted(true)
    }, [])
    if (!hasMounted) {
        return (<Loader/>)
    }

    return (
        <Layout location={props.location} color='#1779FF'>
            <SEO title={f("home")} lang={locale} description={f("descriptionMeta")} />
            <div className="grid grid-cols-1 md:grid-cols-7 xl:gap-2 xl:my-2" style={{
                backgroundImage: `url(${isDark ? waveDark : wave})`,
                backgroundBlendMode: "overlay",
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}>
                <div className='w-screen h-80 absolute z-0 left-0'/>
                <div className="flex justify-center flex-col p-6 md:p-14 gap-4 col-span-3 md:mb-16">
                    <h1 className="font-ExtraBold text-4xl xl:text-5xl z-10">{f("slogan")}</h1>
                    <h2 className="text-lg xl:text-xl z-10">{f("me")}</h2>
                    <ButtonLink label={f("seePortfolio")} link='/portfolio'/>
                    <div className='background-shadow1'/>
                </div>
                <div className='relative flex flex-col justify-center items-center p-6 md:p-14 col-span-4'>
                    <div className='background-shadow2'/>
                    <div className='background-shadow3'/>
                    <div className='banner px-6 relative'>
                        <motion.div
                            className='bg-gray dark:bg-gray-light rounded-10px h-80 md:h-96 md:w-96 md:absolute top-8 left-0 mb-8 md:mb-0 z-30'
                            drag={false}
                            initial={{opacity: 0.9, y: -100}}
                            animate={{opacity: 1, y: 0, transition: {duration: 1.0}}}>
                            <motion.div
                                className='absolute top-6 md:top-12 left-0'
                                drag={false}
                                dragConstraints={{left: 0, right: 0, top: 0, bottom: 0}}
                                initial={{opacity: 0, y: -100}}
                                animate={{opacity: 1, y: 0, transition: {duration: 2}}}>
                                <Img
                                    className='h-80 w-56 md:h-96'
                                    fluid={images.despensaApp.childImageSharp.fluid}
                                    imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                                />
                            </motion.div>
                            <motion.div
                                className='absolute -top-6 md:-top-14 right-0'
                                drag={false}
                                dragConstraints={{left: 0, right: 0, top: 0, bottom: 0}}
                                initial={{opacity: 0, y: -100}}
                                animate={{opacity: 1, y: 0, transition: {duration: 1.5}}}>
                                <Img
                                    className='h-80 w-56 md:h-96'
                                    fluid={images.agendaCaveHome.childImageSharp.fluid}
                                    imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                                />
                            </motion.div>
                        </motion.div>
                        <motion.div
                            className='bg-blue rounded-10px h-80 md:h-96 md:w-96 md:absolute bottom-8 right-0'
                            drag={false}
                            initial={{opacity: 0.8, y: -100}}
                            animate={{opacity: 1, y: 0, transition: {duration: 0.8}}}>
                            <div className='absolute bottom-0 right-0'>
                                <Img
                                    className='h-80 w-80 md:h-96 md:w-96'
                                    fluid={images.diegoUlises.childImageSharp.fluid}
                                    imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                                />
                            </div>

                        </motion.div>
                    </div>
                    <motion.div
                        className='h-16 w-16 md:h-20 md:w-20 rounded-full bg-black  dark:bg-white cursor-move absolute top-8 right-6 md:right-24 z-30'
                        whileTap={{scale: 0.9}}
                        drag={true}
                        dragConstraints={{left: 0, right: 0, top: 0, bottom: 0}}
                        initial={{opacity: 0, y: -100}}
                        animate={{opacity: 1, y: 0, transition: {duration: 1.2}}}/>
                    <motion.div
                        className='h-10 w-10 md:h-11 md:w-11 rounded-full bg-black dark:bg-white cursor-move absolute bottom-8 left-6 md:left-24'
                        whileTap={{scale: 0.9}}
                        drag={true}
                        dragConstraints={{left: 0, right: 0, top: 0, bottom: 0}}
                        initial={{opacity: 0, y: -100}}
                        animate={{opacity: 1, y: 0, transition: {duration: 1.5}}}/>
                </div>
            </div>

            <Stack/>
            <div className="flex flex-col justify-center items-center gap-8 md:gap-24 px-6 py-14 md:px-14 md:py-32">
                <div className='flex flex-col gap-4 justify-center items-center'>
                    <p className='font-ExtraBold text-5xl xl:text-6xl text-center'>{f("collaborateTogether")}</p>
                    <p className='font-Regular text-lg xl:text-xl text-center leading-relaxed xl:leading-loose'>{f("alwaysStriving")}</p>
                    <ButtonLink label={f("goResume")} link='/resume'/>
                    <div className='background-shadow4'/>
                </div>
                <div className='flex flex-col justify-center items-center mt-6 md:hidden'>
                    <p className='font-ExtraBold text-4xl xl:text-6xl text-center text-blue'>{f("dataTitle1")}</p>
                    <p className='font-Regular xl:text-xl text-center'>{f("dataSubTitle1")}</p>
                </div>
                <div className='flex justify-evenly gap-4 w-full'>
                    <div className='flex flex-col justify-center items-center mt-6 hidden md:block'>
                        <p className='font-ExtraBold text-4xl xl:text-6xl text-center text-blue'>{f("dataTitle1")}</p>
                        <p className='font-Regular xl:text-xl text-center'>{f("dataSubTitle1")}</p>
                    </div>
                    <div className='flex flex-col justify-center items-center mt-6'>
                        <div className='background-shadow5'/>
                        <p className='font-ExtraBold text-4xl xl:text-6xl text-center text-blue'>{f("dataTitle2")}</p>
                        <p className='font-Regular xl:text-xl text-center'>{f("dataSubTitle2")}</p>
                    </div>
                    <div className='flex flex-col justify-center items-center mt-6'>
                        <p className='font-ExtraBold text-4xl xl:text-6xl text-center text-blue'>{f("dataTitle3")}</p>
                        <p className='font-Regular xl:text-xl text-center'>{f("dataSubTitle3")}</p>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default IndexPage;
